import React, { useState, useEffect } from 'react';
import { Flex, Box, Button, Text } from 'rebass/styled-components';
import { navigate } from 'gatsby';
import StyledRectangle from '../components/StyledRectangle';
import CardSubtitle from '../components/CardSubtitle';
import Line from '../components/Line';
import { Label, Radio } from '@rebass/forms';
import StepsHeader from '../components/StepsHeader';
import PackageBanner from '../components/PackageBanner';
import { STEPS_CHECKOUT, STORAGE_KEYS } from '../resources/constants';

const PaymentMethod = () => {
  const [packageData, setPackageData] = useState(false);

  useEffect(() => {
    if (!localStorage.getItem(STORAGE_KEYS.PACKAGE_DATA))
      navigate('/next-steps');
    else setPackageData(true);
  }, []);

  const back = () => {
    navigate(`/package-info`);
  };

  const next = () => {
    navigate(`/credit-card`);
  };

  if (!packageData) return null;
  return (
    <Flex
      flexDirection="column"
      justifyContent="center"
      alignContent="center"
      alignItems="center"
      maxWidth="550px"
      width="100%"
      mb={5}
      mt={12}
    >
      <StepsHeader title="Checkout" currentStep="1" steps={STEPS_CHECKOUT} />
      <Box my={2} width={1}>
        <PackageBanner />
      </Box>

      <StyledRectangle bg="white" py={5} width={1}>
        <Box px={5}>
          <CardSubtitle>Payment Method</CardSubtitle>
          <Line height="3px" color="red" my={1} />
          <StyledRectangle bg="white" py={5} my={2} width={1}>
            <Label pl={3} pr={2} justifyContent="space-between">
              <Text color="#8b97a2">Apple Pay</Text>
              <Radio
                color="#8b97a2"
                sx={{
                  'input:checked ~ &': {
                    color: '#d04c50'
                  }
                }}
                disabled
                id="applePay"
                name="type"
              />
            </Label>
          </StyledRectangle>
          <StyledRectangle bg="white" py={5} my={2} width={1}>
            <Label pl={3} pr={2} justifyContent="space-between">
              <Text>Credit Card</Text>
              <Radio
                color="#8b97a2"
                sx={{
                  'input:checked ~ &': {
                    color: '#d04c50'
                  }
                }}
                id="creditCard"
                name="type"
                defaultChecked
              />
            </Label>
          </StyledRectangle>
        </Box>
      </StyledRectangle>
      <Flex
        justifyContent="space-between"
        width={1}
        px={10}
        pt={5}
        sx={{
          paddingBottom: '100px'
        }}
      >
        <Button variant="bWhite" width="87px" onClick={() => back()}>
          Back
        </Button>
        <Button variant="bDarkRed" width="87px" onClick={() => next()}>
          Next
        </Button>
      </Flex>
    </Flex>
  );
};

export default PaymentMethod;
